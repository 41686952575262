import axios, { AxiosResponse } from 'axios';

import { availabilityService, sessionServiceApi } from "../../../config/config";
import { setupInterceptorsTo } from "../interceptors/axios.interceptos";
const axiosInstance = setupInterceptorsTo(axios.create());

export interface PracticeSessionRequest {
    date: string;
    partyId: number;
}

export type PracticeStatusType = 0 | 1;
export type SlotStatusType= 0 | 1;

export interface PracticeToggle {
    id: number;
    practiceStatus: PracticeStatusType;
    practiceSessionId: number;
    sessionDate: string | Date;
}
export interface SlotToggle{
    id:number;
    slotId:number;
    date:string | Date;
    status:SlotStatusType;
}

export interface ApplyLeaveRequest {
    sessionId: number;
    fromDate: string;
    toDate: string;
    validateOnly: boolean;
    session?: any;
}

export interface LeaveListRequest {
    sessionIds?: number[];
    startWith?: string;
}

export interface CancelLeaveRequest {
    leaveIds?: number[]
}

export interface ListAllSessionRequest {
    startDate?: string;
    endDate?: string;
    withInRange?: boolean;
    onlyActiveSessions: boolean;
    partyId: number;
    dayFilter?: boolean;
}
export interface DeleteSlotRequest {
    slotId: number;
    practiceSessionId?: number;
}
class PracticeSessionService {

    async getPracticeSessions(request: PracticeSessionRequest): Promise<AxiosResponse> {
        try {
            return await axiosInstance.get(`${sessionServiceApi.baseUrl}/api/v1/session/list-by-date`, {
                params: request
            });
        } catch (error) {
            throw error;
        }
    }

    async listAllSessions(request: ListAllSessionRequest): Promise<AxiosResponse> {
        try {
            const data=await axiosInstance.get(`${sessionServiceApi.baseUrl}/api/v1/session/list`, {
                params: request
            });
            return data;
        } catch (error) {
            throw error;
        }
    }

    async togglePracticeSession(request: { session: string } & PracticeToggle): Promise<{ data: { id: number, practiceStatus: PracticeStatusType } }> {
        try {
            const url = `${sessionServiceApi.baseUrl}/api/v1/session/${request.practiceSessionId}/toggle`;
            const { data } = await axiosInstance.post(url, request);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async slotToggle(request: { session: string } & SlotToggle): Promise<{ data: { id: number, status: SlotStatusType } }> {
        try {
            const url = `${sessionServiceApi.baseUrl}/api/v1/slot/toggle`;
            const { data } = await axiosInstance.post(url, request);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async getSlots(request: any) {
        try {
            const { practiceSessionId, date } = request;
            const url = `${sessionServiceApi.baseUrl}/api/v1/session/${practiceSessionId}/slots`;
            const { data } = await axiosInstance.get(url, {
                params: {
                    date
                }
            });
            return data;
        } catch (error) {
            throw error;
        }
    }

    async listSlots(request: any) {
        try {
            const { practiceSessionId, date } = request;
            const url = `${sessionServiceApi.baseUrl}/api/v1/slot/list`;
            const { data } = await axiosInstance.post(url, {
                date,
                sessionId: practiceSessionId
            });
            return data;
        } catch (error) {
            throw error;
        }
    }


    async createCustomSession(request: any,partyId:any) {
        try {
            const { data } = await axiosInstance.post(`${sessionServiceApi.baseUrl}/api/v1/session/custom/create?partyId=${partyId}`, request);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async updateSession(request: any,partyId:any) {
        try {
            const { data } = await axiosInstance.put(`${sessionServiceApi.baseUrl}/api/v1/session/${request.sessionId}?partyId=${partyId}`, request);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async saveManageSlots(request: any) {
        try {
            const { data } = await axiosInstance.post(`${sessionServiceApi.baseUrl}/api/v1/slot/toggle`, request);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async listLeaves(request: LeaveListRequest) {
        try {
            const { data } = await axiosInstance.post(`${availabilityService.baseUrl}/api/v1/leave/list`, request);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async deleteLeave(request: CancelLeaveRequest) {
        try {
            const { data } = await axiosInstance.delete(`${availabilityService.baseUrl}/api/v1/leave/cancel`, {
                data: request
            });
            return data;
        } catch (error) {
            throw error;
        }
    }

    async applyLeaveValidate(request: ApplyLeaveRequest[]) {
        try {
            const { data } = await axiosInstance.post(`${availabilityService.baseUrl}/api/v1/leave/apply`, request);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

const practiceSessionService = new PracticeSessionService()
export default practiceSessionService;