
import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { Box, Grid, SvgIcon, IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button/Button';
import ConfirmDialog from "../../../../components/ConfirmDialog";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { PracticeSesContext } from '../../contexts/practice-session.context';
import withHooks from '../../../../utils/withHooks';
import { format } from 'date-fns';

class SlotListCard extends React.Component<any, any> {

    state: any;
    dateNow = format(new Date(), 'yyyy-MM-dd');

    constructor(props: any) {
        super(props);

        this.state = {
            expanded: false,
            confirmDialog: false,
            selectedSlot: {
                edit: false,
                id: null
            },
            errors:{},
            isAddingNewSlot: props.isAddingNewSlot || false,
            originalSlotData: null
        }
    }

    onClickEdit = (id: number): void => {
        const { sessionSlots } = this.props.practiceSesContext;
        
        // Store the original slot data before editing
        const originalSlot = sessionSlots.find((slot: any) => slot.id === id);
        
        this.setState({ 
            selectedSlot: { edit: true, id },
            originalSlotData: { ...originalSlot } // Store original data
        });
    }

    
   onClickDelete = (id: number): void => {
        this.setState({ selectedSlot: { edit: false, id }, confirmDialog: true });
    }
    
    deleteSlot = (): void => {
        const { selectedSlot } = this.state;
        const { setPracticeSessionsSlots, sessionSlots } = this.props?.practiceSesContext;
        const _allSlots = [...sessionSlots];
        const indx = _allSlots.findIndex((slot: any) => slot.id === selectedSlot?.id);
        _allSlots.splice(indx, 1);
        setPracticeSessionsSlots(_allSlots);
        this.setState({ confirmDialog: false });
    }
   
    validateSlots = (): boolean => {
        const errors: {
            regularSlots?: string;
            walkinSlots?: string;
        } = {};
        
        if (!this.props.regularSlots && this.props.regularSlots !== 0) {
            errors.regularSlots = 'Regular slots cannot be blank';
        }
        
        if (!this.props.walkinSlots && this.props.walkinSlots !== 0) {
            errors.walkinSlots = 'Walkin slots cannot be blank';
        }

        this.setState({ errors });
        return Object.keys(errors).length === 0;
    };


    onChangeSlots = (e: any, attr: string, id: number): void => {
        const { setPracticeSessionsSlots, sessionSlots } = this.props?.practiceSesContext;
        const _allSlots = sessionSlots;
        const indx = sessionSlots.findIndex((slot: any) => slot.id === id);

        if (attr === "fromTime" || attr === "toTime") {
            const frmtTime = attr === "fromTime" ? "fromTimeFrmt" : "toTimeFrmt";
            _allSlots[indx][attr] = e.format('HH:mm:ss');
            _allSlots[indx][frmtTime] = format(e.toDate(), "hh:mm a");
        }
        else {
            let totalSlots;
            if (attr === "walkinSlots") {
                totalSlots = parseInt(_allSlots[indx]["regularSlots"]) + (e.target.value ? parseInt(e.target.value) : 0);
            } else {
                totalSlots = parseInt(_allSlots[indx]["walkinSlots"]) + (e.target.value ? parseInt(e.target.value) : 0);
            }
            _allSlots[indx][attr] = e.target.value;
            _allSlots[indx]["totalSlots"] = totalSlots;

               // Clear error when value is entered
            const errors = { ...this.state.errors };
            if (attr === "regularSlots" && e.target.value) {
                delete errors.regularSlots;
            } else if (attr === "walkinSlots" && e.target.value) {
                delete errors.walkinSlots;
            }
            this.setState({ errors }); 
        }
        setPracticeSessionsSlots(_allSlots);
    }

    onSaveChanges = (): void => {
        if (!this.validateSlots()) {
            return;
        }
        const { setPracticeSessionsSlots, sessionSlots } = this.props?.practiceSesContext;
        this.setState({ selectedSlot: { edit: false } ,errors:{}});
        setPracticeSessionsSlots(sessionSlots.map((slot: any) => {
            if (slot?.editable) slot.editable = false;
            return slot
        }))
    }
    handleEditCancel = (id: number): void => {
        const { setPracticeSessionsSlots, sessionSlots } = this.props.practiceSesContext;
        
        // Restore the original data
        const updatedSlots = sessionSlots.map((slot: any) => {
            if (slot.id === id) {
                return { ...this.state.originalSlotData };
            }
            return slot;
        });
        
        setPracticeSessionsSlots(updatedSlots);
        this.setState({
            selectedSlot: { edit: false, id: null },
            originalSlotData: null
        });
    }

     
    closeModal = (): void => {
        const { selectedSlot } = this.state;
        
        if (selectedSlot.edit) {
            this.handleEditCancel(selectedSlot.id);
        } else if (this.props.onSlotCancel) {
            this.props.onSlotCancel(this.props.id);
        }
        
        this.setState({
            selectedSlot: { edit: false, id: null },
            isAddingNewSlot: false,
        });
    }

    get practiceSesContext() {
        return this.props.practiceSesContext;
    }

    render(): React.ReactNode {
        const { selectedSlot, confirmDialog } = this.state;
        const { fromTimeFrmt, toTimeFrmt, id, fromTime, toTime, totalSlots, regularSlots, walkinSlots, editable } = this.props;

        return (
            <React.Fragment>
                <ConfirmDialog
                    title={`Confirmation`}
                    body={`Would you like to delete the slot?`}
                    open={confirmDialog}
                    buttonProps={
                        [{
                            label: 'Cancel'
                        },
                        {
                            label: 'Ok'
                        }]
                    }
                    onClose={() => this.setState({ confirmDialog: false })}
                    confirmCallback={() => this.deleteSlot()}
                />
                <Card sx={{ padding: '0px', mb: '0.75rem' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                            <Grid item xs={!selectedSlot?.edit && !editable ? 9 : 12} >
                                <Box sx={{
                                    padding: '1rem !important',
                                    '.MuiInputBase-input': {
                                        padding: '9%'
                                    },
                                    '.MuiInputBase-root': { boxShadow: 'unset' }
                                }}>
                                    {
                                        !selectedSlot?.edit && !editable ? (
                                            <React.Fragment>
                                                <Typography variant="subtitle1" component="span">
                                                    {fromTimeFrmt} - {toTimeFrmt}
                                                </Typography>
                                                <Stack direction="row" spacing={1} sx={{
                                                    marginTop: '1rem',
                                                    '& .MuiChip-root': {
                                                        height: 25
                                                    }
                                                }}>
                                                    <Chip label={`Regular: ${regularSlots}`} />
                                                    <Chip label={`WalkIn: ${walkinSlots}`} />
                                                    <Chip label={`Total: ${totalSlots}`} />
                                                </Stack>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer
                                                        components={[
                                                            'TimePicker',
                                                            'MobileTimePicker',
                                                            'DesktopTimePicker',
                                                            'StaticTimePicker',]}
                                                    >
                                                        <DemoItem label="Select Time">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="space-between"
                                                                spacing={2}
                                                            >
                                                                <TimePicker
                                                                    value={dayjs(format(new Date(`${this.dateNow} ${fromTime}`), "yyyy-MM-dd'T'HH:mm"))}
                                                                    onChange={(newValue) => this.onChangeSlots(newValue, "fromTime", id)}
                                                                    defaultValue={dayjs(format(new Date(`${this.dateNow} ${fromTime}`), "yyyy-MM-dd'T'HH:mm"))}
                                                                />
                                                                <TimePicker
                                                                    value={dayjs(format(new Date(`${this.dateNow} ${toTime}`), "yyyy-MM-dd'T'HH:mm"))}
                                                                    onChange={(newValue) => this.onChangeSlots(newValue, "toTime", id)}
                                                                    defaultValue={dayjs(format(new Date(`${this.dateNow} ${toTime}`), "yyyy-MM-dd'T'HH:mm"))}
                                                                />
                                                            </Stack>
                                                        </DemoItem>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    spacing={2}
                                                    sx={{ mt: 2 }}
                                                >
                                                    <Stack >
                                                        <Typography variant="body2" gutterBottom>
                                                            Regular
                                                        </Typography>
                                                        <TextField
                                                            type="number"
                                                            value={this.props.regularSlots}
                                                            onChange={(e) => this.onChangeSlots(e, "regularSlots", id)}
                                                            error={!!this.state.errors.regularSlots}
                                                            helperText={this.state.errors.regularSlots}
                                                        />
                                                    </Stack>
                                                    <Stack >
                                                        <Typography variant="body2" gutterBottom>
                                                            WalkIn
                                                        </Typography>
                                                        <TextField
                                                            type="number"
                                                            value={this.props.walkinSlots}
                                                            onChange={(e) => this.onChangeSlots(e, "walkinSlots", id)}
                                                            error={!!this.state.errors.walkinSlots}
                                                            helperText={this.state.errors.walkinSlots}
                                                        />
                                                    </Stack>
                                                    <Stack >
                                                        <Typography variant="body2" gutterBottom>
                                                            Total
                                                        </Typography>
                                                        <TextField
                                                            disabled
                                                            type="number"
                                                            value={this.props.totalSlots}
                                                            onChange={(e) => this.onChangeSlots(e, "totalSlots", id)}
                                                        />
                                                    </Stack>
                                                </Stack>
                                                <Typography textAlign={"end"} sx={{ mt: 1 }}>
                                                    <Button
                                                        size="small"
                                                        onClick={() => this.closeModal()}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        sx={{ ml: '5px' }}
                                                        onClick={() => this.onSaveChanges()}
                                                    >
                                                        OK
                                                    </Button>
                                                </Typography>
                                            </React.Fragment>
                                        )
                                    }
                                </Box>
                            </Grid>
                            {
                                !selectedSlot?.edit && !editable ? (<Grid item xs={3} md={3}>
                                    <Box sx={{
                                        textAlign: 'right'
                                    }}>
                                        <Typography color="text.primary" component="span" >
                                            <IconButton onClick={() => this.onClickEdit(id)}>
                                                <SvgIcon fontSize="small"> <EditIcon /></SvgIcon>
                                            </IconButton>
                                        </Typography>
                                        <Typography color="text.primary" component="span" >
                                            <IconButton color="error" onClick={() => this.onClickDelete(id)}>
                                                <SvgIcon fontSize="small"> <DeleteIcon /></SvgIcon>
                                            </IconButton>
                                        </Typography>
                                    </Box>
                                </Grid>) : ("")
                            }
                        </Grid>
                    </Box>
                </Card>
            </React.Fragment>
        )
    }
}

export default withHooks(SlotListCard, [
    ['practiceSesContext', useContext, [PracticeSesContext]],
]);