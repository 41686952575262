import React, { useContext } from 'react';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog, DialogContent, useMediaQuery,
    useTheme, Accordion, AccordionSummary, AccordionDetails, Grid, TextField,
    Checkbox, Button, Box
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import withHooks from '../../../../utils/withHooks';
import { PracticeSesContext } from '../../contexts/practice-session.context';
import SlotListCard from './ListSlotsCard';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import practiceSessionService, { ListAllSessionRequest } from '../../../../services/http/practice-sessions/practice-session.service';
import { format } from 'date-fns';
import { weekDaysMapping } from '../../../leave/components/ApplyLeave';
import { getAttributeFromToken } from '../../../../hooks/use-auth';
import { AppContext } from '../../../../contexts/app.context';
import practiceSessionValidation from '../../validation/manage-practice-session';
import practiceSessionSlotValidation from '../../validation/manage-session-slots';

const slotMockData: any = {
    "id": 1688801692324,
    "regularSlots": 4,
    "totalSlots": 6,
    "walkinSlots": 2,
    "isSlotIsOpen": 1,
    "totalAppt": "0",
    "fromTime": "13:00:00",
    "toTime": "13:20:00",
    "fromTimeFrmt": "01:00 PM",
    "toTimeFrmt": "01:20 PM",
    "slotDuration": "20.0000",
    "minSlotPrefNum": null,
    "maxSlotPrefNum": null,
    "startApptNumber": null,
    "maxApptNumber": null,
    "futureTimeAppt": 0,
    "selectedSlot": 0,
    "editable": true
}

export const CUSTOM_DAY = 8

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ManageSessionModal extends React.Component<any, any, {}> {

    state: any;
    dateNow = format(new Date(), 'yyyy-MM-dd');
    scrollViewRef: any = React.createRef()

    constructor(props: any) {
        super(props);
        this.state = {
            sessionSlots: [],
            session: true,
            slots: false,
            updatBtnIsDisabled: false,
            selecedSession: this.props.id,
            consultingStartTime: dayjs(format(new Date(), "yyyy-MM-dd'T'HH:mm")),
            consultingEndTime: dayjs(format(new Date(), "yyyy-MM-dd'T'HH:mm")),
            bookingStartTime: dayjs(format(new Date(), "yyyy-MM-dd'T'HH:mm")),
            bookingEndTime: dayjs(format(new Date(), "yyyy-MM-dd'T'HH:mm")),
            selectedDays: [],
            customDate: {
                selected: false,
                fromDate: new Date(),
                toDate: new Date()
            }
        }
    }

    onUpdateChanges = async (): Promise<any> => {
        const { appContext } = this.props;
        this.setState({ updatBtnIsDisabled: true });
        const { manageSessionSelected, sessionSlots } = this.practiceSesContext;
        try {
            let message;
            const { customDate } = this.state;
            const { selecedSession, consultingStartTime, consultingEndTime,
                bookingStartTime, bookingEndTime, selectedDays, authAssignmentId, day, psDate } = manageSessionSelected;
            const request = {
                sessionId: selecedSession,
                consultingFromTime: format(consultingStartTime.toDate(), 'HH:mm:ss'),
                consultingToTime: format(consultingEndTime.toDate(), 'HH:mm:ss'),
                apptBookingFromTime: format(bookingStartTime.toDate(), 'HH:mm:ss'),
                apptBookingToTime: format(bookingEndTime.toDate(), 'HH:mm:ss'),
                days: (customDate?.selected || !day) ? [] : selectedDays,
                isCustom: customDate?.selected || !day ? true : false,
                forceCustom: false,
                customSessionOptions: {
                    fromDate: customDate?.fromDate ? format(customDate?.fromDate, 'yyyy-MM-dd') : "",
                    toDate: customDate?.toDate ? format(customDate?.toDate, 'yyyy-MM-dd') : ""
                },
                slots: this.formatSlots(sessionSlots, selecedSession),
                authAssignmentId
            }
            const sessionResp = new practiceSessionValidation(request).validate();
            const slotResp = new practiceSessionSlotValidation(request).validate();
            if (sessionResp?.status || slotResp?.status) {
                message = sessionResp?.status ? sessionResp.message : slotResp?.message;
                appContext.toast({ open: true, message: message, severity: "warning", duration: 5000 });
                return
            }

            console.log(request, day)

            let data: any;
            if (request.isCustom && day) {
                data = await practiceSessionService.createCustomSession(request, getAttributeFromToken('partyId'));
            } else  {

                if (psDate) {
                    request.customSessionOptions = {
                        fromDate: psDate,
                        toDate: psDate
                    }
                }
               
                data = await practiceSessionService.updateSession(request, getAttributeFromToken('partyId'));
            }
           
            await this.getPracticeSessions();
            this.onCloseModal()
            appContext.toast({ open: true, message: data.data, severity: "success" });
        } catch (error: any) {
            appContext.toast({ open: true, message: error?.response?.data?.error, severity: "warning", duration: 5000 });
        } finally {
            this.setState({ updatBtnIsDisabled: false });
        }
    }

    onCloseModal = (): void => {
        const { setManageSesModal } = this.practiceSesContext;
        setManageSesModal(false);
        this.updateCustomDate();
    }

    updateCustomDate = () => {
        this.setState({
            customDate: {
                selected: false,
                fromDate: new Date(),
                toDate: new Date()
            }
        })
    }

    async getPracticeSessions() {
        const request: ListAllSessionRequest = {
            partyId: getAttributeFromToken('partyId'),
            onlyActiveSessions: true,
            dayFilter: false
        }
        const { data: practiceSessions } = (await practiceSessionService.listAllSessions(request)).data;
        this.setState({ ...this.state, practiceSessions });
        this.props.practiceSesContext.setPracticeSessions(practiceSessions);
    }

    formatSlots = (slots: any[], sessionId: number): any => {
        return slots.map((slot: any) => {
            return {
                "id": !slot?.isNew ? slot?.id : null,
                "practiceSessionId": sessionId,
                "fromTime": slot?.fromTime,
                "toTime": slot?.toTime,
                "regularSlots": slot?.regularSlots,
                "walkinSlots": slot?.walkinSlots,
                "totalSlots": slot?.totalSlots
            }
        })
    }

    onChangePracticeSession = (e: React.ChangeEvent<any>) => {
        this.setState({ selectedSession: e.target.value });
    }

    onSelectTime = (timeType: string, time: any): void => {
        this.setState({ [timeType]: time })
    }

    getSelectedSession = (id: number): any => {
        const { practiceSessions } = this.practiceSesContext;
        const [session] = practiceSessions.filter((pses: any) => pses.practiceSessionId === id);
        return session ? session : {}
    }

    onSelectDays = (e: any, indx: number): void => {
        const { manageSessionSelected } = this.props?.practiceSesContext;
        const { selectedDays } = manageSessionSelected;

        const day = ++indx;
        const checked = e?.target?.checked;
        let _selectedDays = selectedDays;

        if (checked) {
            if (day === CUSTOM_DAY) _selectedDays = [day];
            if (day !== CUSTOM_DAY) _selectedDays = [..._selectedDays, day]

        }

        if (!checked) {
            const index = _selectedDays.indexOf(day);
            if (index > -1) _selectedDays.splice(index, 1);
        }
        this.onChangeSessionDetails("selectedDays", _selectedDays);
        this.setState({
            selectedDays: _selectedDays,
            customDate: {
                selected: checked && day === CUSTOM_DAY,
                fromDate: new Date(),
                toDate: new Date()
            }
        })
    }

    onChangeCustDate = (e: any, attr: string) => {
        this.setState(((prevSate: any) => {
            prevSate.customDate[attr] = e;
            if (attr === "fromDate") {
                prevSate.customDate["toDate"] = e;
            }
            return prevSate;
        }));
    }


    get practiceSesContext() {
        return this.props.practiceSesContext;
    }

    get appContext() {
        return this.props.appContext;
    }

    isDaySelected = (day: string, indx: number): boolean => {
        const { manageSessionSelected } = this.practiceSesContext;
        const { selectedDays, } = manageSessionSelected;
        return selectedDays.includes(++indx);
    }

    onChangeSessionDetails = (arrt: string, value: string) => {
        const { setManageSessionSelected, manageSessionSelected } = this.practiceSesContext;
        const _manageSessionSelected = manageSessionSelected;
        _manageSessionSelected[arrt] = value;
        setManageSessionSelected(_manageSessionSelected)
    }

    addNewSlot = (): void => {
        const { setPracticeSessionsSlots, sessionSlots } = this.props?.practiceSesContext;
        const _sessionSlots = JSON.parse(JSON.stringify(sessionSlots));
        if (_sessionSlots.length) {
            const [lastSlot] = _sessionSlots.splice(-1);
            lastSlot["id"] = Date.now();
            lastSlot["editable"] = true;
            lastSlot["isNew"] = true;
            setPracticeSessionsSlots([...sessionSlots, ...[lastSlot]]);
        } else {
            slotMockData["id"] = Date.now();
            slotMockData["editable"] = true;
            slotMockData["isNew"] = true;
            setPracticeSessionsSlots([...sessionSlots, ...[slotMockData]]);
        }

        this.scrollViewRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    handleSlotCancel = (slotId: number): void => {
        const { setPracticeSessionsSlots, sessionSlots } = this.props.practiceSesContext;
        
        // If it's a new slot, remove it
        if (sessionSlots.find((slot:any) => slot.id === slotId)?.isNew) {
            const filteredSlots = sessionSlots.filter((slot:any) => slot.id !== slotId);
            setPracticeSessionsSlots(filteredSlots);
        }
    }

    render(): React.ReactNode {
        const {
            session, slots, customDate } = this.state;
        const { sessionSlots, openManageSesModal, manageSessionSelected } = this.practiceSesContext;
        const { consultingStartTime, consultingEndTime, bookingStartTime, bookingEndTime, psDate, day } = manageSessionSelected;
        const days = weekDaysMapping([1, 2, 3, 4, 5, 6, 7]).split("-");

        return <>
            <Dialog
                fullScreen={this.props.isMd}
                open={openManageSesModal}
                TransitionComponent={Transition}
                sx={{
                    "MuiPaper-root": {
                        paddingRight: "0px"
                    },
                }}
            >
                <AppBar sx={{
                    width: `${!this.props.isMd ? '100%' : 'auto'}`,
                    maxWidth: "100%",
                    position: 'relative',
                    background: 'none',
                    boxShadow: 'none !important',
                    color: 'neutral.900'
                }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Practice session details
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={() => this.onCloseModal()}

                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Accordion
                        expanded={session}
                        sx={{ boxShadow: 'unset' }}
                        onChange={() => this.setState({ session: !this.state.session, slots: !this.state.slots })}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ padding: "0px" }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Sessions
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: "8px 0px 16px" }}>
                            <Grid container spacing={3} justifyContent="flex-start">
                                <Grid item xs={12} md={12} lg={12} sm container sx={{ pt: '0px !important' }}>
                                    <TextField
                                        disabled
                                        value={`${this.getSelectedSession(this.props.id).orgName} - ${this.getSelectedSession(this.props.id).area}`}
                                        sx={{
                                            mb: 1,
                                            minWidth: '100%',
                                            '.MuiInputBase-input': {
                                                padding: '3%'
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6} lg={6} sm container
                                    sx={{
                                        mb: 1,
                                        pt: '0px !important',
                                        '.MuiInputBase-input': {
                                            padding: '9%'
                                        },
                                        '.MuiInputBase-root': { boxShadow: 'unset' }
                                    }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'TimePicker',
                                                'MobileTimePicker',
                                                'DesktopTimePicker',
                                                'StaticTimePicker',
                                            ]}
                                        >
                                            <DemoItem label="Consulting Start Time">
                                                <TimePicker
                                                    value={consultingStartTime ?? ''}
                                                    onChange={(newValue) => this.onChangeSessionDetails("consultingStartTime", newValue)}
                                                    defaultValue={dayjs('2022-04-17T15:30')}
                                                />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6} sm container
                                    sx={{
                                        mb: 1,
                                        pt: '0px !important',
                                        '.MuiInputBase-input': {
                                            padding: '9%'
                                        },
                                        '.MuiInputBase-root': { boxShadow: 'unset' }
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'TimePicker',
                                                'MobileTimePicker',
                                                'DesktopTimePicker',
                                                'StaticTimePicker',
                                            ]}
                                        >
                                            <DemoItem label="Consulting End Time">
                                                <TimePicker
                                                    value={consultingEndTime ?? ''}
                                                    onChange={(newValue) => this.onChangeSessionDetails("consultingEndTime", newValue)}
                                                    defaultValue={dayjs('2022-04-17T15:30')}
                                                />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6} sm container
                                    sx={{
                                        mb: 1,
                                        pt: '0px !important',
                                        '.MuiInputBase-input': {
                                            padding: '9%'
                                        },
                                        '.MuiInputBase-root': { boxShadow: 'unset' }
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'TimePicker',
                                                'MobileTimePicker',
                                                'DesktopTimePicker',
                                                'StaticTimePicker',
                                            ]}
                                        >
                                            <DemoItem label="Booking Start Time">
                                                <TimePicker
                                                    value={bookingStartTime ?? ''}
                                                    onChange={(newValue) => this.onChangeSessionDetails("bookingStartTime", newValue)}
                                                    defaultValue={dayjs('2022-04-17T15:30')}
                                                />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6} sm container
                                    sx={{
                                        mb: 1,
                                        pt: '0px !important',
                                        '.MuiInputBase-input': {
                                            padding: '9%'
                                        },
                                        '.MuiInputBase-root': { boxShadow: 'unset' }
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'TimePicker',
                                                'MobileTimePicker',
                                                'DesktopTimePicker',
                                                'StaticTimePicker',
                                            ]}
                                        >
                                            <DemoItem label="Booking End Time">
                                                <TimePicker
                                                    value={bookingEndTime ?? ''}
                                                    onChange={(newValue) => this.onChangeSessionDetails("bookingEndTime", newValue)}
                                                    defaultValue={dayjs('2022-04-17T15:30')}
                                                />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} container justifyContent={"flex-start"}
                                    sx={{
                                        pt: '0px !important',
                                    }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem label="Active Days">
                                            <Grid container justifyContent="start">
                                                {
                                                    [...days, 'Custom'].map((d: any, indx: number) => {
                                                        return (
                                                            <Grid item xs={4} md={3} key={d}>
                                                                <span style={{ paddingRight: "6px" }}>
                                                                    <Checkbox
                                                                        checked={this.isDaySelected(d, indx)}
                                                                        disabled={!day ? true : false}
                                                                        onChange={(e) => this.onSelectDays(e, indx)}
                                                                        name={d}
                                                                        size="small"
                                                                        key={d}
                                                                    />
                                                                    <Typography variant="subtitle1" display="inline" gutterBottom>
                                                                        {d}
                                                                    </Typography>
                                                                </span>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </DemoItem>
                                    </LocalizationProvider>
                                </Grid>
                                {
                                    !day && <Grid item xs={12} md={12} lg={12} sm container sx={{
                                        mt: 1,
                                        pt: '0px !important', '.MuiInputBase-input': {
                                            padding: '9%'
                                        },
                                        '.MuiInputBase-root': { boxShadow: 'unset' },
                                        '.MuiStack-root': { width: "100%" }
                                    }}>
                                        <DemoItem label="Custom Date">
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                spacing={2}
                                                sx={{ mt: 2 }}>
                                                <MobileDatePicker
                                                    closeOnSelect={true}
                                                    sx={{ width: "50%" }}
                                                    minDate={new Date()}
                                                    format="dd-MM-yyyy"
                                                    value={new Date(psDate)}
                                                    defaultValue={new Date()}
                                                    disabled
                                                />
                                            </Stack>
                                        </DemoItem>
                                    </Grid>
                                }
                                {
                                    this.state.customDate?.selected && <Grid item xs={12} md={12} lg={12} sm container
                                        sx={{
                                            pt: '0px !important', '.MuiInputBase-input': {
                                                padding: '9%'
                                            },
                                            '.MuiInputBase-root': { boxShadow: 'unset' },
                                            '.MuiStack-root': { width: "100%" }
                                        }}
                                    >
                                        <DemoItem label="Custom Date">
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                spacing={2}
                                                sx={{ mt: 2 }}>
                                                <MobileDatePicker
                                                    closeOnSelect={true}
                                                    sx={{ width: "50%" }}
                                                    minDate={new Date()}
                                                    format="dd-MM-yyyy"
                                                    value={customDate?.fromDate}
                                                    onChange={(e) => this.onChangeCustDate(e, "fromDate")}
                                                    defaultValue={new Date()}
                                                />
                                                <Typography component="span" sx={{ mt: 5 }}>
                                                    {" - "}
                                                </Typography>
                                                <MobileDatePicker
                                                    closeOnSelect={true}
                                                    sx={{ width: "50%" }}
                                                    minDate={customDate?.fromDate}
                                                    format="dd-MM-yyyy"
                                                    value={customDate?.toDate}
                                                    onChange={(e) => this.onChangeCustDate(e, "toDate")}
                                                    defaultValue={new Date()}
                                                />
                                            </Stack>
                                        </DemoItem>
                                    </Grid>
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={slots}
                        sx={{ boxShadow: 'unset' }}
                        onChange={() => this.setState({ slots: !this.state.slots, session: !this.state.session })}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ padding: "0px" }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Slots
                            </Typography>
                        </AccordionSummary>
                        <Box sx={{ textAlign: "end" }}>
                            <Button variant="text" onClick={() => this.addNewSlot()}>Add</Button>
                        </Box>
                        <AccordionDetails sx={{ padding: "8px 0px 16px" }}>
                            {
                                sessionSlots.length ? (
                                    <React.Fragment>
                                        {
                                            sessionSlots.map((slot: any, indx: number) => {
                                                return <SlotListCard 
                                                    key={`${indx}-${slot.id}`} 
                                                    {...slot} 
                                                    allSlots={sessionSlots}
                                                    onSlotCancel={this.handleSlotCancel}  
                                                />
                                            })    
                                        }
                                    </React.Fragment>
                                ) : ("")
                            }
                        </AccordionDetails>
                    </Accordion>
                    <Typography textAlign={"end"}>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => this.onCloseModal()}
                        >Cancel</Button>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={this.onUpdateChanges}
                            disabled={this.state.updatBtnIsDisabled}
                            sx={{ ml: '5px' }}
                        >
                            Update
                        </Button>
                    </Typography>
                    <div ref={this.scrollViewRef as React.RefObject<HTMLDivElement>} />
                </DialogContent>
            </Dialog>
        </>
    }
}

export default withHooks(ManageSessionModal, [
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['practiceSesContext', useContext, [PracticeSesContext]],
    ['appContext', useContext, [AppContext]],
    ['theme', useTheme]
]);