import { format } from 'date-fns';
import React, { createContext, useContext } from 'react';
import practiceSessionService from '../../../services/http/practice-sessions/practice-session.service';
import withHooks from '../../../utils/withHooks';
import { ApptContext } from '../../appointments/contexts/appointment.context';
import { getAttributeFromToken } from '../../../hooks/use-auth';
import dayjs from 'dayjs';


export interface PracticeSesContextState {
    practiceSessions: any[];
    sessionSlots: any[];
    manageSessionSelected: Object;
    selectedPracticeSession: any;
    isApplyFilter: boolean;
    isOpenManageSlotModal: boolean;
    isSlotLoading: boolean;
    openManageSesModal: boolean;
    partyId: number;
    editSlots: Object,
    isClinicLeave: boolean
}

type PracticeSesContextType = {
    practiceSessions: any[];
    isOpenManageSlotModal: boolean;
    partyId: number;
    setPracticeSessions: (value: any[]) => void;
    setManageSessionSelected: (value: any) => void;
    setSelectedPracticeSession: (value: any) => void;
    setSeletectedSession: (value: any) => void;
    loadSlots: (sessionId: number, date: string) => void;
    setContextState: (state: any) => void;
    setIsClinicIsLeave: (value: boolean) => void
};

export const PracticeSesContext = createContext<PracticeSesContextType | null>(null);

class PracticeSesContextProvider extends React.Component<any, any> {

    state: PracticeSesContextState;

    constructor(props: any) {
        super(props);

        this.state = {
            practiceSessions: [],
            manageSessionSelected: {
                selecedSession: null,
                authAssignmentId: null,
                consultingStartTime: dayjs(format(new Date(), "yyyy-MM-dd'T'HH:mm")),
                consultingEndTime: dayjs(format(new Date(), "yyyy-MM-dd'T'HH:mm")),
                bookingStartTime: dayjs(format(new Date(), "yyyy-MM-dd'T'HH:mm")),
                bookingEndTime: dayjs(format(new Date(), "yyyy-MM-dd'T'HH:mm")),
                selectedDays: [],
            },
            sessionSlots: [], //to use in manage session comp
            selectedPracticeSession: {},
            isClinicLeave: false,
            isApplyFilter: false,
            isOpenManageSlotModal: false,
            isSlotLoading: false,
            openManageSesModal: false,
            partyId: getAttributeFromToken('partyId'),
            editSlots: {
                regularSlots: '',
                totalSlots: '',
                walkinSlots: '',
            }
        }
    }

    setManageSesModal = (openManageSesModal: boolean) => this.setState({ openManageSesModal })

    getPracticeSessions = async () => {
        try {
            const request = {
                date: format(new Date(this.props.apptContext.filter.scheduleOn), 'yyyy-MM-dd'),
                partyId: this.state.partyId
            }
            const { data: practiceSessions } = await (await practiceSessionService.getPracticeSessions(request)).data;
            return practiceSessions;
        } catch (error) {
            return []
        }
    }

    loadPracticeSessions = async () => {
        const practiceSessions = await this.getPracticeSessions();
        let selectedSession: any = {}
        let practiceSessionId = '';
        if (practiceSessions.length) {
            selectedSession = practiceSessions.find((p: any) => p.isSelect) ?? practiceSessions[0];
            practiceSessionId = selectedSession.practiceSessionId;
        } else {
            this.setState({ isClinicLeave: true })
        }

        this.setState({
            practiceSessions,
            practiceSessionId
        });
        this.setPracticeSessions(practiceSessions);
        this.setSeletectedSession(practiceSessionId);
        this.props.apptContext.setFilterValues('practiceSessionId', practiceSessionId);
    }

    loadSlots = async (practiceSessionId: number, date: string, refresh = false) => {
        this.setState({ isOpenManageSlotModal: !this.state.isOpenManageSlotModal })


        if (this.state.practiceSessions) {
            let { practiceSessions } = this.state;
            const practiceSession = practiceSessions.find((ses: any) => ses.practiceSessionId === practiceSessionId);
            console.log("practiceSession", practiceSession)
            if (practiceSession.slots) {
                await this.setSlots(practiceSessionId, practiceSession.slots);
                return;
            }

        }

        // if (this.state.selectedPracticeSession.slots) {
        //     return true;
        // }

        this.setState({ isSlotLoading: true })
        const request = {
            practiceSessionId,
            date: format(new Date(date), 'yyyy-MM-dd')
        }
        const { data } = await practiceSessionService.getSlots(request);
        setTimeout(() => {
            this.setState({ isSlotLoading: false })
        }, 300);
        await this.setSlots(practiceSessionId, data);
    }

    setSlots = async (sessionId: number, slots: any[]) => {
        let { practiceSessions } = this.state;
        practiceSessions = practiceSessions.map((ses: any) => {
            if (ses.practiceSessionId === sessionId) {
                return {
                    ...ses,
                    slots: slots.map((s: any) => ({ ...s, original: Object.assign({}, s) }))
                }
            }
            return ses;
        });
        this.setPracticeSessions(practiceSessions);
        this.setSeletectedSession(sessionId);
    }

    setPracticeSessions = (practiceSessions: any) => {
        this.setState({ practiceSessions });
    }

    setPracticeSessionsSlots = (sessionSlots: any) => {
        this.setState({ sessionSlots });
    }

    setManageSessionSelected = (manageSessionSelected: any) => {
        this.setState({ manageSessionSelected });
    }

    setSelectedPracticeSession = (selectedPracticeSession: any) => {
        this.setState({ selectedPracticeSession });
    }

    setSeletectedSession = (id: number | string) => {
        setTimeout(() => {
            this.setState({
                selectedPracticeSession: this.state.practiceSessions.find((p: any) => p.practiceSessionId === id)
            });
        }, 1);
    }

    setSlotCounts = (editSlots: any) => {
        this.setState({ editSlots });
    }

    setPracticeToggle = (id: number, practiceStatus: any, selectedSession: any) => {
        const { practiceSessions } = this.state;
        this.setState({
            practiceSessions: practiceSessions.map((session: any) => {
                if (session.practiceSessionId === selectedSession.practiceSessionId) {
                    session = {
                        ...session,
                        practiceToggleId: id,
                        practiceStatus
                    }
                }
                return session;
            }),
            selectedPracticeSession: {
                ...selectedSession,
                practiceToggleId: id,
                practiceStatus
            }
        })
    }

    setSlotToggle = (id: number, status: any, selectedSession: any) => {
        const { practiceSessions } = this.state;
        this.setState({
            practiceSessions: practiceSessions.map((session: any) => {
                if (session.slotId === selectedSession.slotId) {
                    return {
                        ...session,
                        toggleId: id,
                        status
                    };
                }
                return session;
            }),
            selectedPracticeSession: {
                ...selectedSession,
                toggleId: id,
                status
            }
        });
    }
    

    setContextState = (state: any) => this.setState({ ...state });

    setIsClinicIsLeave = (isClinicLeave: boolean) => this.setState({ isClinicLeave });


    private contextValues = () => ({
        ...this.state,
        setPracticeSessions: this.setPracticeSessions,
        setPracticeSessionsSlots: this.setPracticeSessionsSlots,
        setSelectedPracticeSession: this.setSelectedPracticeSession,
        setSeletectedSession: this.setSeletectedSession,
        loadPracticeSessions: this.loadPracticeSessions,
        setPracticeToggle: this.setPracticeToggle,
        loadSlots: this.loadSlots,
        setSlots: this.setSlots,
        setContextState: this.setContextState,
        getPracticeSessions: this.getPracticeSessions,
        setManageSesModal: this.setManageSesModal,
        setSlotCounts: this.setSlotCounts,
        setManageSessionSelected: this.setManageSessionSelected,
        setIsClinicIsLeave: this.setIsClinicIsLeave
    })

    render(): React.ReactNode {
        return (
            <PracticeSesContext.Provider value={this.contextValues()}>
                {this.props.children}
            </PracticeSesContext.Provider>
        )
    }
}


export default withHooks(PracticeSesContextProvider, [
    ['apptContext', useContext, [ApptContext]]
]);